.bsl-table-global thead tr th {
  /* position: relative; */
  /* width: fit-content; */

  font-family: var(--text-small-medium-font-family);
  font-weight: var(--text-small-medium-font-weight);
  color: var(--contentdark);
  font-size: var(--text-small-medium-font-size);
  letter-spacing: var(--text-small-medium-letter-spacing);
  line-height: var(--text-small-medium-line-height);
  white-space: nowrap;
  font-style: var(--text-small-medium-font-style);
}

.bsl-table-global tbody tr td {
  font-family: var(--text-small-regular-font-family);
  font-weight: var(--text-small-regular-font-weight);
  color: var(--contentnormal);
  font-size: var(--text-small-regular-font-size);
  letter-spacing: var(--text-small-regular-letter-spacing);
  line-height: var(--text-small-regular-line-height);
  font-style: var(--text-small-regular-font-style);
}

.bsl-table-global {
  table-layout: fixed;
  width: 100%;
}

.bsl-table-container {
  overflow-y: auto;
  padding: 20px;
}

.bsl-table-global > thead > tr {
  vertical-align: middle;
}

.w-check-40 {
  width: 40px !important;
}

.w-delete-70 {
  width: 70px !important;
}

.table-active {
  --bs-table-bg-state: var(--whitewhite-hover) !important;
}
.width-t {
  /* width: 150px !important; */
}
.parent-t {
  position: absolute;
  left: 0;
  right: 0;
  max-width: 100% !important;
  display: block;
  overflow-x: auto;
}
.resp-width {
  width: 150px !important;
}
.date-width {
  width: 80px !important;
}

.w-t-10 {
  width: 10px !important;
}
.w-t-20 {
  width: 20px !important;
}
.w-t-30 {
  width: 30px !important;
}
.w-t-40 {
  width: 40px !important;
}
.w-t-50 {
  width: 50px !important;
}
.w-t-60 {
  width: 60px !important;
}
.w-t-70 {
  width: 70px !important;
}
.w-t-80 {
  width: 80px !important;
}
.w-t-90 {
  width: 90px !important;
}
.w-t-100 {
  width: 100px !important;
}
.w-t-110 {
  width: 110px !important;
}
.w-t-120 {
  width: 120px !important;
}
.w-t-130 {
  width: 130px !important;
}
.w-t-140 {
  width: 140px !important;
}
.w-t-150 {
  width: 150px !important;
}
.w-t-160 {
  width: 160px !important;
}
.w-t-170 {
  width: 170px !important;
}
.w-t-180 {
  width: 180px !important;
}
.w-t-190 {
  width: 190px !important;
}
.w-t-200 {
  width: 200px !important;
}
.w-t-210 {
  width: 210px !important;
}
.w-t-220 {
  width: 220px !important;
}
.w-t-230 {
  width: 230px !important;
}
.w-t-240 {
  width: 240px !important;
}
.w-t-250 {
  width: 250px !important;
}
.w-t-260 {
  width: 260px !important;
}
.w-t-270 {
  width: 270px !important;
}
.w-t-280 {
  width: 280px !important;
}
.w-t-290 {
  width: 290px !important;
}
.w-t-300 {
  width: 300px !important;
}
