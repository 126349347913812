.root-content-page {
  display: flex;
  width: 100%;
  height: 97%; /* Assurez-vous que le parent de .root-content-page a une hauteur définie */
  align-items: flex-start;
  position: relative;
  background-color: var(--surfacelight);
  border-radius: 15px;
  overflow: hidden; /* Ajouté pour éviter les débordements inattendus */
}

.root-content-page .prototype-nav {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}
.root-content-page .content-content {
  gap: 20px;
  padding: 20px 40px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  overflow-y: auto;
}