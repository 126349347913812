.chart-wrapper {
  float: left;
  padding-bottom: 40%;
  position: relative;
  width: 45%;
}
.bsl-bar-chart > div.highcharts-container {
  width: 100% !important;
}
.bsl-bar-chart {
  width: 100% !important;
  min-height: 500px !important;
}

.row-bar-chart {
  margin-bottom: -55px;
  z-index: 5;
  margin-top: 10px !important;
}
.bsl-bar-chart-card > .card-body {
  display: grid;
}
.bsl-bar-chart-card > .card-header {
  background-color: #ffffff;
}
