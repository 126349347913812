.bsl-widget {
  background-color: #ffffff;
  color: var(--contentnormal);
  display: inline-block;
  width: 100%;
}
.bsl-widget .coma {
  color: rgba(14, 63, 235, 1) !important;
  font-size: 7px;
}
.bsl-widget .icon_start {
  font-size: 15px;
}

.bsl-widget-mr-btn {
  margin-right: -25px !important;
}
