.modal.fade:not(.show) .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.addNewModalDialogCustomSize {
  width: 70% !important;
  height: 100% !important;
}

.green-button-bsl {
  background-color: #00d78c !important;
  color: #ffffff !important;
}

.green-button-bsl:hover {
  background-color: #00c67b !important;
  color: #ffffff !important;
}

.green-button-bsl:active {
  background-color: #005435 !important;
  color: #ffffff !important;
}
.bsl-show-modal {
  display: block !important;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
  max-height: calc(100vh - 145px);
}

.dropdown-item {
  text-decoration: solid !important;
}

.dropdown-item:hover {
  background-color: rgba(14, 63, 235, 0.2) !important;
}

.dot {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50% !important;
  display: inline-block !important;
}
.facturation-modal {
  min-width: 550px;
}
.facturation-modal .modal-title i {
  font-size: 16px;
  font-weight: 100;
}
.react-dual-listbox select {
  min-height: 250px !important;
}
.h-item-invoice {
  max-height: 55vh;
  overflow-y: auto;
}
.h-item-invoice i {
  font-size: 12px;
}
