.nav-admin {
  display: inline-flex;
  flex-direction: column;
  height: 100vh;
  min-height: 730px;
  align-items: flex-start;
  padding: 10px 0px 10px 10px;
  position: relative;
  border-radius: 10px;
  transition: all 0.3s ease;
  width: auto;
  /* position: fixed; */
}

@media screen and (min-width: 1537px) {
  .nav-admin {
    height: 100vh;
  }
  .nav-admin {
    height: 100vh;
  }
}

.nav-admin .div {
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  background-color: var(--bluedark-hover);
  border-radius: 10px;
  display: flex;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  height: 100%;
  transition: width 0.5s ease-in-out;
}

.nav-admin .logo {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 30px 30px;
  position: relative;
  align-self: stretch;
  width: 220px;
  transition: width 0.5s ease-in-out;
}

.nav-admin .logo {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 30px 30px;
  position: relative;
  align-self: stretch;
  width: 220px;
  transition: width 0.5s ease-in-out;
}

.nav-admin .logo-collapse {
  width: 70px;
  padding: 0px 10px 30px 10px;
  height: 100px;
}

.nav-admin .img {
  position: relative;
  height: 38px;
  width: 116px;
  /* margin-right: 25px; */
}

.nav-admin .img {
  position: relative;
  height: 38px;
  width: 116px;
  /* margin-right: 25px; */
}

.nav-admin .img-collapse {
  width: 50px !important;
  /* margin-right: 0px !important; */
}

.nav-admin .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--bluenormal);
  border-radius: 100px;
}

.nav-admin .button-collapse {
  border-radius: 100px;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  top: 70px;
  left: 57px;
  transform: rotate(-180deg);
  background-color: var(--bluenormal);
  align-items: center;
  justify-content: center;
  border: 2px solid aliceblue;
}

.nav-admin .button-collapse {
  border-radius: 100px;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  top: 70px;
  left: 57px;
  transform: rotate(-180deg);
  background-color: var(--bluenormal);
  align-items: center;
  justify-content: center;
  border: 2px solid aliceblue;
}

.nav-admin .img-2 {
  position: relative;
  width: 16px;
  height: 16px;
}

.nav-admin .menu {
  display: flex;
  flex-direction: column;
  width: 220px;
  align-items: center;
  gap: 10px;
  padding: 0px 0px 20px;
  position: relative;
  flex: 0 0 auto;
  transition: width 0.5s ease-in-out;
}

.nav-admin .menu {
  display: flex;
  flex-direction: column;
  width: 220px;
  align-items: center;
  gap: 10px;
  padding: 0px 0px 20px;
  position: relative;
  flex: 0 0 auto;
  transition: width 0.5s ease-in-out;
}
.nav-admin .menu-collapse {
  width: 70px !important;
  margin-top: 50px;
}

.nav-admin .item-nav {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 0px 15px;
  position: relative;
  align-self: stretch;
  width: auto;
  border-radius: 200px;
  cursor: pointer;
}

.nav-admin .div-2 {
  align-items: center;
  gap: 10px;
  padding: 0px 15px;
  border-radius: 200px;
  display: flex;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.nav-admin .img-3 {
  position: relative;
  width: 20px;
  height: 20px;
}
.nav-admin .div-3 .img-3 {
  position: relative;
  width: 20px;
  height: 20px;
  filter: invert(0.5) sepia(1) saturate(50) hue-rotate(210deg);
}
.nav-admin .div-3 .img-3 {
  position: relative;
  width: 20px;
  height: 20px;
  filter: invert(0.5) sepia(1) saturate(50) hue-rotate(210deg);
}

.nav-admin .label {
  font-family: var(--text-small-regular-font-family);
  font-weight: var(--text-small-regular-font-weight);
  color: var(--bluelight);
  position: relative;
  width: fit-content;
  font-size: var(--text-small-regular-font-size);
  letter-spacing: var(--text-small-regular-letter-spacing);
  line-height: var(--text-small-regular-line-height);
  white-space: nowrap;
  font-style: var(--text-small-regular-font-style);
}

.nav-admin .divider {
  position: relative;
  width: 190px;
  height: 1px;
  background-color: #b4c3f94c;
}
.nav-admin .divider-collapse {
  width: 55px !important;
}

.nav-admin .itemnav-black {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 10px;
  position: relative;
  align-self: stretch;
  border-radius: 200px;
  cursor: pointer;
}

.nav-admin .div-3 {
  align-items: center;
  gap: 10px;
  padding: 0px 15px;
  background-color: var(--whitewhite);
  border-radius: 6px;
  display: flex;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.nav-admin .text-wrapper {
  font-family: var(--text-small-medium-font-family);
  font-weight: var(--text-small-medium-font-weight);
  color: var(--bluenormal);
  position: relative;
  width: fit-content;
  font-size: var(--text-small-medium-font-size);
  letter-spacing: var(--text-small-medium-letter-spacing);
  line-height: var(--text-small-medium-line-height);
  white-space: nowrap;
  font-style: var(--text-small-medium-font-style);
}

.nav-admin .divider-collapse {
  width: 55px !important;
}

.nav-admin .itemnav-black {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 10px;
  position: relative;
  align-self: stretch;
  border-radius: 200px;
  cursor: pointer;
}

.nav-admin .div-3 {
  align-items: center;
  gap: 10px;
  padding: 0px 15px;
  background-color: var(--whitewhite);
  border-radius: 6px;
  display: flex;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.nav-admin .text-wrapper {
  font-family: var(--text-small-medium-font-family);
  font-weight: var(--text-small-medium-font-weight);
  color: var(--bluenormal);
  position: relative;
  width: fit-content;
  font-size: var(--text-small-medium-font-size);
  letter-spacing: var(--text-small-medium-letter-spacing);
  line-height: var(--text-small-medium-line-height);
  white-space: nowrap;
  font-style: var(--text-small-medium-font-style);
}

.nav-admin .frame {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 15px;
  flex: 1;
  align-self: stretch;
  width: auto;
  flex-grow: 1;
  display: flex;
  position: relative;
}
.nav-admin .frame-collapse {
  padding: 0px !important;
}
.nav-admin .frame-collapse {
  padding: 0px !important;
}

.nav-admin .profil {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  align-self: stretch;
  width: auto;
  flex: 0 0 auto;
  background-color: var(--bluedark);
  border-radius: 6px;
}
.nav-admin .profil-collapse {
  margin-left: 10px;
  margin-right: auto;
}
.nav-admin .profil-collapse {
  margin-left: 10px;
}

.nav-admin .frame-2 {
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: auto;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}

.nav-admin .avatar {
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  background-color: var(--bluelight);
  border-radius: 100px;
}

.nav-admin .s {
  position: relative;
  width: fit-content;
  margin-top: -0.5px;
  margin-left: -2px;
  margin-right: -2px;
  font-family: var(--text-small-regular-font-family);
  font-weight: var(--text-small-regular-font-weight);
  color: var(--bluenormal);
  font-size: var(--text-small-regular-font-size);
  text-align: center;
  letter-spacing: var(--text-small-regular-letter-spacing);
  line-height: var(--text-small-regular-line-height);
  white-space: nowrap;
  font-style: var(--text-small-regular-font-style);
}

.nav-admin .frame-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.nav-admin .text-wrapper-2 {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  width: fit-content;
  font-family: var(--text-verysmall-regular-font-family);
  font-weight: var(--text-verysmall-regular-font-weight);
  color: var(--whitewhite);
  font-size: var(--text-verysmall-regular-font-size);
  letter-spacing: var(--text-verysmall-regular-letter-spacing);
  line-height: var(--text-verysmall-regular-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-style: var(--text-verysmall-regular-font-style);
}

.nav-admin .text-wrapper-3 {
  position: relative;
  width: fit-content;
  font-family: var(--text-verysmall-regular-font-family);
  font-weight: var(--text-verysmall-regular-font-weight);
  color: var(--whitewhite);
  font-size: var(--text-verysmall-regular-font-size);
  letter-spacing: var(--text-verysmall-regular-letter-spacing);
  line-height: var(--text-verysmall-regular-line-height);
  white-space: nowrap;
  font-style: var(--text-verysmall-regular-font-style);
}

.nav-admin .button-ghostwhite {
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: relative;
  align-self: stretch;
  width: auto;
  border-radius: 4px;
}

.nav-admin .label-2 {
  font-family: var(--text-small-medium-font-family);
  font-weight: var(--text-small-medium-font-weight);
  color: var(--whitewhite);
  position: relative;
  width: fit-content;
  font-size: var(--text-small-medium-font-size);
  letter-spacing: var(--text-small-medium-letter-spacing);
  line-height: var(--text-small-medium-line-height);
  white-space: nowrap;
  font-style: var(--text-small-medium-font-style);
}

.nav-admin .property-1-default {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 30px 15px 30px 30px;
}

.nav-admin .property-1-1-default {
  display: flex;
  width: 220px;
}

.nav-admin .property-1-1-collapse {
  display: inline-flex;
}

.nav-admin .property-1-2-default {
  padding: 15px;
}

.nav-admin .property-1-2-collapse {
  padding: 15px 10px;
}
.nav-admin.reduced {
  width: auto;
}

.nav-admin.reduced .label {
  display: none;
}

.nav-admin.reduced .label-2 {
  display: none;
}

.nav-admin.reduced .frame-3 {
  display: none;
}

.nav-admin .property-1-default {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 30px 15px 30px 30px;
}

.nav-admin .property-1-1-default {
  display: flex;
  width: 220px;
}

.nav-admin .property-1-1-collapse {
  display: inline-flex;
}

.nav-admin .property-1-2-default {
  padding: 15px;
}

.nav-admin .property-1-2-collapse {
  padding: 15px 10px;
}
.nav-admin.reduced {
  width: auto;
}

.nav-admin.reduced .label {
  display: none;
}

.nav-admin.reduced .label-2 {
  display: none;
}

.nav-admin.reduced .frame-3 {
  display: none;
}
