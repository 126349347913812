.template-mail-size {
  min-width: 65% !important;
}

.mail-textarea-size {
  resize: none;
}

.pj-size {
  width: 100% !important;
  min-height: 250px !important;
  max-height: 250px !important;
}

.pj-size-body {
  width: 100% !important;
  min-height: 15px !important;
  max-height: 150px !important;
}

.pj-size-footer {
  width: 100% !important;
  min-height: 50px !important;
  max-height: 50px !important;
}
