.nav-gray .nav-link {
  color: #000;
  border: none !important;
  border-bottom: 3px solid #7e76761e !important;
  transition: all 1s;
}

.nav-gray .nav-gray .nav-link.active {
  background-color: transparent;
}
.nav-link.active {
  color: #000 !important;
  border: none !important;
  border-bottom: 2px solid rgb(26, 26, 223) !important;
}
.nav-link .nav-link:hover {
  color: #6c757d;
}
