.w-p-1 {
  width: 1% !important;
}
.w-p-2 {
  width: 2% !important;
}
.w-p-3 {
  width: 3% !important;
}
.w-p-4 {
  width: 4% !important;
}
.w-p-5 {
  width: 5% !important;
}

.w-p-6 {
  width: 6% !important;
}
.w-p-7 {
  width: 7% !important;
}
.w-p-8 {
  width: 8% !important;
}
.w-p-9 {
  width: 9% !important;
}
.w-p-10 {
  width: 10% !important;
}
.w-p-11 {
  width: 11% !important;
}
.w-p-12 {
  width: 12% !important;
}
.w-p-13 {
  width: 13% !important;
}
.w-p-14 {
  width: 14% !important;
}
.w-p-15 {
  width: 15% !important;
}
.w-p-16 {
  width: 16% !important;
}
.w-p-17 {
  width: 17% !important;
}
.w-p-18 {
  width: 18% !important;
}
.w-p-19 {
  width: 19% !important;
}
.w-p-20 {
  width: 20% !important;
}
.w-p-21 {
  width: 21% !important;
}
.w-p-22 {
  width: 22% !important;
}
.w-p-23 {
  width: 23% !important;
}
.w-p-24 {
  width: 24% !important;
}
.w-p-25 {
  width: 25% !important;
}
.w-p-26 {
  width: 26% !important;
}
.w-p-27 {
  width: 27% !important;
}
.w-p-28 {
  width: 28% !important;
}
.w-p-29 {
  width: 29% !important;
}
.w-p-30 {
  width: 30% !important;
}
.w-p-31 {
  width: 31% !important;
}
.w-p-32 {
  width: 32% !important;
}
.w-p-33 {
  width: 33% !important;
}
.w-p-34 {
  width: 34% !important;
}
.w-p-35 {
  width: 35% !important;
}
.w-p-36 {
  width: 36% !important;
}
.w-p-37 {
  width: 37% !important;
}
.w-p-38 {
  width: 38% !important;
}
.w-p-39 {
  width: 39% !important;
}
.w-p-40 {
  width: 40% !important;
}
.w-p-41 {
  width: 41% !important;
}

.w-p-42 {
  width: 42% !important;
}
.w-p-43 {
  width: 43% !important;
}
.w-p-44 {
  width: 44% !important;
}
.w-p-45 {
  width: 45% !important;
}
.w-p-46 {
  width: 46% !important;
}
.w-p-47 {
  width: 47% !important;
}
.w-p-48 {
  width: 48% !important;
}
.w-p-49 {
  width: 49% !important;
}
.w-p-50 {
  width: 50% !important;
}

.w-p-51 {
  width: 51% !important;
}
.w-p-52 {
  width: 52% !important;
}
.w-p-53 {
  width: 53% !important;
}
.w-p-54 {
  width: 54% !important;
}
.w-p-55 {
  width: 55% !important;
}
.w-p-56 {
  width: 56% !important;
}
.w-p-57 {
  width: 57% !important;
}
.w-p-58 {
  width: 58% !important;
}
.w-p-59 {
  width: 59% !important;
}
.w-p-60 {
  width: 60% !important;
}
.w-p-61 {
  width: 61% !important;
}
.w-p-62 {
  width: 62% !important;
}
.w-p-63 {
  width: 63% !important;
}
.w-p-64 {
  width: 64% !important;
}
.w-p-65 {
  width: 65% !important;
}
.w-p-66 {
  width: 66% !important;
}
.w-p-67 {
  width: 67% !important;
}
.w-p-68 {
  width: 68% !important;
}
.w-p-69 {
  width: 69% !important;
}
.w-p-70 {
  width: 70% !important;
}
.w-p-71 {
  width: 71% !important;
}
.w-p-72 {
  width: 72% !important;
}
.w-p-73 {
  width: 73% !important;
}
.w-p-74 {
  width: 74% !important;
}
.w-p-75 {
  width: 75% !important;
}
.w-p-76 {
  width: 76% !important;
}
.w-p-77 {
  width: 77% !important;
}
.w-p-78 {
  width: 78% !important;
}
.w-p-79 {
  width: 79% !important;
}
.w-p-80 {
  width: 80% !important;
}
.w-p-81 {
  width: 81% !important;
}
.w-p-82 {
  width: 82% !important;
}
.w-p-83 {
  width: 83% !important;
}
.w-p-84 {
  width: 84% !important;
}
.w-p-85 {
  width: 85% !important;
}
.w-p-86 {
  width: 86% !important;
}
.w-p-87 {
  width: 87% !important;
}
.w-p-88 {
  width: 88% !important;
}
.w-p-89 {
  width: 89% !important;
}
.w-p-90 {
  width: 90% !important;
}
.w-p-91 {
  width: 91% !important;
}
.w-p-92 {
  width: 92% !important;
}
.w-p-93 {
  width: 93% !important;
}
.w-p-94 {
  width: 94% !important;
}
.w-p-95 {
  width: 95% !important;
}
.w-p-96 {
  width: 96% !important;
}
.w-p-97 {
  width: 97% !important;
}
.w-p-98 {
  width: 98% !important;
}
.w-p-99 {
  width: 99% !important;
}
.w-p-100 {
  width: 100% !important;
}
