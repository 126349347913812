.invalid-input-colors {
  --bs-bg-opacity: 1;
  outline: none !important;
  border-color: #d40000 !important;
  background-color: #fbe6e6 !important;
}

.input-error-message {
  color: #df4040 !important;
  font-size: 0.7em !important;
}

.input-text-size {
  font-size: var(--text-small-regular-font-size) !important;
  letter-spacing: var(--text-small-regular-letter-spacing) !important;
}

.input-label-size {
  font-family: var(--text-small-regular-font-family) !important;
  font-weight: var(--text-small-regular-font-weight) !important;
  font-size: var(--text-small-regular-font-size) !important;
  letter-spacing: var(--text-small-regular-letter-spacing) !important;
  line-height: var(--text-small-regular-line-height) !important;
  white-space: nowrap !important;
  font-style: var(--text-small-regular-font-style) !important;
}

.section-label-size {
  font-family: var(--text-small-regular-font-family) !important;
  color: var(--contentdark) !important;
  font-size: var(--text-small-regular-font-size) !important;
  letter-spacing: var(--text-small-regular-letter-spacing) !important;
  line-height: var(--text-small-regular-line-height) !important;
  white-space: nowrap !important;
}
