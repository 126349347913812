.bsl-table {
  display: flex;
  flex-direction: column;
  /* height: 68.5%; */
  align-items: flex-start;
  /* gap: 10px; */
  position: relative;
  align-self: stretch;
  width: auto;
  z-index: 0;
  background-color: #ffffff;
  padding: 0px 0px;
}
