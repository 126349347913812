.thc-search {
  /* max-width: 244px !important; */
}
.normal-font-size {
  font-size: var(--text-small-medium-font-size) !important;
}

.table-tabs {
  z-index: 1;
  display: flex;
  /* width: 1130px; */
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  position: relative;
  flex: 0 0 auto;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--surfacenormal);
}
.tabs-rows {
  align-items: flex-start;
  padding: 4px;
  background-color: var(--whitewhite-hover);
  border-radius: 1000px;
  display: inline-flex;
  gap: 5px;
  position: relative;
  flex: 0 0 auto;
}

.bsl-serach-input {
  max-width: 224px !important;
}
