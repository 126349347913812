.connexion {
  display: flex;
  width: 99.25%;
  height: 100vh;
  position: relative;
  background-color: var(--surfacelight);
  border-radius: 15px;
  overflow: auto;
}

/* Media query for larger screens */
@media screen and (min-width: 1537px) {
  .connexion {
    min-height: 915px;
  }
}

/* Media query for smaller screens (e.g., phones) */
@media screen and (max-width: 780px) {
  .connexion {
    min-height: 1018px;
    flex-direction: column;
    /* Change flex-direction to column for smaller screens */
    height: auto;
  }

  .connexion .frame {
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 5px;
  }
}

.connexion .frame {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.connexion .slide-out {
  animation: slideOut 1s forwards;
}

.connexion .transct {
  transition: width 1s ease-in-out;
}

.connexion .dialog-connexion-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  border-radius: 10px;
  transition: transform 5s ease-in-out;
}

.connexion .dialog-connexion {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  min-width: 325px;
  gap: 40px;
  padding: 40px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--whitewhite);
  border-radius: 10px;
  box-shadow: var(--large);
  opacity: 1;
  transition: min-width 0.5s ease-in-out;
}
.connexion .nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  background-color: var(--bluedark-hover);
  border-radius: 10px;
  height: auto;
}

.connexion .logo {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  padding: 30px;
  position: relative;
  align-self: stretch;
  width: auto;
}

.connexion .img {
  position: relative;
  width: 320px;
  height: 105px;
  margin-top: -7.5px;
  margin-bottom: -7.5px;
}

.connexion .hidden {
  opacity: 0;
  min-width: 0;
}

.connexion .div {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: relative;
  flex: 0 0 auto;
}

.connexion .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--headings-title-1-font-family);
  font-weight: var(--headings-title-1-font-weight);
  color: var(--bluenormal);
  font-size: var(--headings-title-1-font-size);
  text-align: center;
  letter-spacing: var(--headings-title-1-letter-spacing);
  line-height: var(--headings-title-1-line-height);
  white-space: nowrap;
  font-style: var(--headings-title-1-font-style);
}

.connexion .text-wrapper-2 {
  position: relative;
  width: fit-content;
  font-family: var(--headings-title-2-font-family);
  font-weight: var(--headings-title-2-font-weight);
  color: var(--contentnormal);
  font-size: var(--headings-title-2-font-size);
  text-align: center;
  letter-spacing: var(--headings-title-2-letter-spacing);
  line-height: var(--headings-title-2-line-height);
  white-space: nowrap;
  font-style: var(--headings-title-2-font-style);
}

.connexion .frame-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.connexion .input {
  flex-direction: column;
  width: 300px;
  gap: 10px;
  flex: 0 0 auto;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.connexion .label {
  display: flex;
  height: 12px;
  align-items: center;
  gap: 4px;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.connexion .field-label {
  position: relative;
  width: fit-content;
  font-family: var(--text-small-regular-font-family);
  font-weight: var(--text-small-regular-font-weight);
  color: var(--contentdark);
  font-size: var(--text-small-regular-font-size);
  letter-spacing: var(--text-small-regular-letter-spacing);
  line-height: var(--text-small-regular-line-height);
  white-space: nowrap;
  font-style: var(--text-small-regular-font-style);
}

.connexion .input-2 {
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  position: relative;
  background-color: var(--surfacelight);
}

.connexion .input-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  width: auto;
  border: 1px solid var(--surfacenormal);
  border-radius: 4px;
  padding: 10px;
  background-color: var(--surfacelight);
}

.connexion .input-field input {
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 10px;
  background-color: transparent;
  color: var(--contentlight-hover);
  font-weight: bold;
}

.connexion .img-2 {
  position: relative;
  width: 20px;
  height: 20px;
}

.connexion .pd {
  padding-right: 7px;
}

.connexion .helper-value {
  display: flex;
  height: 20px;
  align-items: center;
  gap: 10px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.connexion .field-label-2 {
  position: relative;
  width: fit-content;
  font-family: var(--text-small-regular-font-family);
  font-weight: var(--text-small-regular-font-weight);
  color: var(--contentdark);
  font-size: var(--text-small-regular-font-size);
  letter-spacing: var(--text-small-regular-letter-spacing);
  line-height: var(--text-small-regular-line-height);
  white-space: nowrap;
  font-style: var(--text-small-regular-font-style);
}

.connexion .error {
  border-color: var(--rednormal);
  background-color: rgba(230, 11, 11, 0.04);
  color: var(--rednormal);
}

.connexion .frame-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  position: relative;
  align-self: stretch;
  width: auto;
  flex: 0 0 auto;
}

.connexion form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
}

.connexion .button-primary {
  height: 44px;
  padding: 12px;
  background-color: var(--bluenormal);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  align-self: stretch;
  width: auto;
  border-radius: 4px;
  border: none;
}

.connexion .button-primary:hover {
  background-color: var(--bluenormal-hover);
  color: var(--whitewhite);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.connexion .label-2 {
  position: relative;
  width: fit-content;
  font-family: var(--text-normal-medium-font-family);
  font-weight: var(--text-normal-medium-font-weight);
  color: var(--whitewhite);
  font-size: var(--text-normal-medium-font-size);
  letter-spacing: var(--text-normal-medium-letter-spacing);
  line-height: var(--text-normal-medium-line-height);
  white-space: nowrap;
  font-style: var(--text-normal-medium-font-style);
}

.connexion .button-ghost {
  height: 32px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  align-self: stretch;
  width: auto;
  border-radius: 4px;
}

.connexion .p {
  position: relative;
  width: fit-content;
  font-family: var(--text-small-medium-font-family);
  font-weight: var(--text-small-medium-font-weight);
  color: var(--contentlight);
  font-size: var(--text-small-medium-font-size);
  letter-spacing: var(--text-small-medium-letter-spacing);
  line-height: var(--text-small-medium-line-height);
  white-space: nowrap;
  font-style: var(--text-small-medium-font-style);
}

.connexion .bienvenue {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--headings-title-2-font-family);
  font-weight: var(--headings-title-2-font-weight);
  color: var(--bluenormal);
  font-size: var(--headings-title-2-font-size);
  text-align: center;
  letter-spacing: var(--headings-title-2-letter-spacing);
  line-height: var(--headings-title-2-line-height);
  white-space: nowrap;
  font-style: var(--headings-title-2-font-style);
}

.connexion .s-bastien {
  position: relative;
  width: fit-content;
  font-family: var(--text-extralarge-regular-font-family);
  font-weight: var(--text-extralarge-regular-font-weight);
  color: var(--contentnormal);
  font-size: var(--text-extralarge-regular-font-size);
  text-align: center;
  letter-spacing: var(--text-extralarge-regular-letter-spacing);
  line-height: var(--text-extralarge-regular-line-height);
  font-style: var(--text-extralarge-regular-font-style);
}

.connexion .error-message {
  display: flex;
  align-items: center;
  color: var(--rednormal);
  font-size: 9.5px;
  margin-top: -2.5%;
  font-weight: var(--text-small-regular-font-weight);
  font-family: var(--text-extralarge-regular-font-family);
}

.connexion .error-message > p {
  display: contents;
}

.connexion .error-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.connexion .spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
