/* file input */
label.guZdik {
  min-width: 96%;
  margin-top: 20px;
}

.container {
  position: relative;
  width: 100%;
}

.rectangle {
  width: 75%;
  height: 200px; /* Ajustez la hauteur selon vos besoins */
  background-color: #f0f0f0; /* Couleur du rectangle */
  border: 2px dashed blue; /* Bordure en pointillés bleus */
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; /* Couleur du texte */
}

.text-overlay p {
  margin: 0;
  padding: 10px;
}

.centered-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #007bff; /* Couleur du bouton */
  color: white; /* Couleur du texte du bouton */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.guZdik {
  min-width: 95%;
  margin-top: 10px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-width: 322px;
  max-width: 508px;
  border: 2px dashed rgb(6, 88, 194);
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-flex: 0;
  flex-grow: 0;
  height: 6em !important;
  padding-left: 30px;
  padding-right: 30px;
}

.jWkLDY {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
img,
svg {
  vertical-align: middle;
}
.juBESy {
  position: relative;
  display: contents;
}

.file-description {
  display: flex;
  flex-direction: column;
}

.file-description-top {
  font-size: 14px;
  margin-bottom: 5px;
}
.ligne_fichier {
  width: 100% !important;
  /* background-color: #f5f6f3; */
  font-size: 0.9em !important;
}

.file-description-bottom {
  font-size: 12px;
  color: #888;
}
.icon_left {
  margin-right: 15px;
}

.progress {
  width: 100%;
  height: 2px !important;
}

.fadeIn {
  animation: fadeInAnimation 0.5s forwards ease;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
