.bsl-pb-5 {
  height: 5px !important;
}
.bsl-pb-6 {
  height: 6px !important;
}
.bsl-pb-7 {
  height: 7px !important;
}
.bsl-pb-8 {
  height: 8px !important;
}
.bsl-pb-9 {
  height: 9px !important;
}
.bsl-pb-10 {
  height: 10px !important;
}
.bsl-pb-11 {
  height: 11px !important;
}
.bsl-pb-12 {
  height: 12px !important;
}
.bsl-pb-13 {
  height: 13px !important;
}
.bsl-pb-14 {
  height: 14px !important;
}
.bsl-pb-15 {
  height: 15px !important;
}
.bsl-pb-16 {
  height: 16px !important;
}
.bsl-pb-17 {
  height: 17px !important;
}
.bsl-pb-18 {
  height: 18px !important;
}
.bsl-pb-19 {
  height: 19px !important;
}
.bsl-pb-20 {
  height: 20px !important;
}
