.form-check-input:checked {
  background-color: rgba(14, 63, 235, 1) !important;
  border-color: rgba(14, 63, 235, 1) !important;
}
/***************************** FORM ROUND*****************************/
.fr-tabs-item {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 4px 4px 4px 8px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--whitewhite);
  border-radius: 100px;
}
.fr-label-wrapper {
  display: inline-flex;
  height: 16px;
  align-items: center;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
}
.fr-label-5 {
  position: relative;
  width: fit-content;
  font-family: var(--text-verysmall-medium-font-family);
  font-weight: var(--text-verysmall-medium-font-weight);
  color: var(--bluenormal);
  font-size: var(--text-verysmall-medium-font-size);
  letter-spacing: var(--text-verysmall-medium-letter-spacing);
  line-height: var(--text-verysmall-medium-line-height);
  white-space: nowrap;
  font-style: var(--text-verysmall-medium-font-style);
}
.fr-notif-number {
  display: inline-flex;
  min-width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 4px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--bluenormal);
  border-radius: 100px;
}
.fr-element {
  font-family: var(--text-verysmall-medium-font-family);
  font-weight: var(--text-verysmall-medium-font-weight);
  color: var(--whitewhite);
  position: relative;
  flex: 1;
  margin-top: -0.5px;
  font-size: var(--text-verysmall-medium-font-size);
  text-align: center;
  letter-spacing: var(--text-verysmall-medium-letter-spacing);
  line-height: var(--text-verysmall-medium-line-height);
  font-style: var(--text-verysmall-medium-font-style);
}

.fr-tabs-item-2 {
  align-items: center;
  padding: 4px 4px 4px 8px;
  border-radius: 100px;
  display: inline-flex;
  gap: 5px;
  position: relative;
  flex: 0 0 auto;
}

.fr-label-6 {
  position: relative;
  width: fit-content;
  font-family: var(--text-verysmall-regular-font-family);
  font-weight: var(--text-verysmall-regular-font-weight);
  color: var(--contentlight);
  font-size: var(--text-verysmall-regular-font-size);
  letter-spacing: var(--text-verysmall-regular-letter-spacing);
  line-height: var(--text-verysmall-regular-line-height);
  white-space: nowrap;
  font-style: var(--text-verysmall-regular-font-style);
}

.fr-element-wrapper {
  display: inline-flex;
  min-width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 4px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--whitewhite);
  border-radius: 100px;
}

.fr-element-2 {
  font-family: var(--text-verysmall-regular-font-family);
  font-weight: var(--text-verysmall-regular-font-weight);
  color: var(--contentlight);
  position: relative;
  flex: 1;
  margin-top: -0.5px;
  font-size: var(--text-verysmall-regular-font-size);
  text-align: center;
  letter-spacing: var(--text-verysmall-regular-letter-spacing);
  line-height: var(--text-verysmall-regular-line-height);
  font-style: var(--text-verysmall-regular-font-style);
}
.bsl-search-drop > button {
  background-color: var(--whitewhite-hover);
  font-size: var(--text-normal-regular-font-size);
  border-color: var(--whitewhite-hover);
  color: var(--contentlight);
  padding: 6px !important;
}
.bsl-dropdown-serach-content > li {
  min-width: 250px;
}
.bsl-dropdown-serach-content > li > a {
  color: var(--contentlight);
  font-size: var(--text-normal-regular-font-size);
  padding: 5px 10px 5px 5px !important;
}
.bsl-dropdown-serach-content {
  max-height: 450px;
  overflow-y: auto;
}
.bsl-btn-valider-date {
  background-color: #04d68d !important;
  color: #fcfcfd !important;
}
.bsl-btn-annuler-date {
  background-color: #d1e1e2 !important;
  color: #495057 !important;
}
.bsl-dropdown-serach-content > .item-bsl-dropdown {
  max-height: 330px;
  overflow-y: auto;
}
.bsl-dropdown-serach-content > .item-bsl-dropdown > li > a {
  color: var(--contentlight);
  font-size: var(--text-normal-regular-font-size);
  padding: 5px 10px 5px 5px !important;
  text-decoration: none;
}
.bsl-dropdown-serach-content > .item-bsl-dropdown > li {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}

.bsl-dropdown-serach-content > .item-bsl-dropdown > li:hover {
  background-color: #9ea7b3;
  /* color: var(--whitewhite); */
}
.bsl-dropdown-serach-content > .item-bsl-dropdown > li:hover > a {
  color: var(--whitewhite);
  font-size: var(--text-normal-regular-font-size);
  padding: 5px 10px 5px 5px !important;
  text-decoration: none;
}

.bsl-dropdown-serach-content > .item-bsl-dropdown > li.active {
  background-color: var(--bluenormal-selected-bg) !important;
}
.bsl-dropdown-serach-content > .item-bsl-dropdown > li.active > a {
  color: var(--bluenormal-selected-color) !important;
}
.item-bsl-dropdown-all > a {
  color: var(--whitewhite);
  font-size: var(--text-normal-regular-font-size);
  padding: 5px 10px 5px 5px !important;
  text-decoration: none;
}
.item-bsl-dropdown-all:hover {
  background-color: #9ea7b3;
}
.item-bsl-dropdown-all:hover > a {
  color: var(--whitewhite);
}

.bsl-dropdown-serach-content > li.item-bsl-dropdown-all.active {
  background-color: var(--bluenormal-selected-bg) !important;
}
.bsl-dropdown-serach-content > li.item-bsl-dropdown-all.active > a {
  color: var(--bluenormal-selected-color) !important;
}
