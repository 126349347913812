.pagination-container {
  margin-left: auto;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.pagination {
  display: flex;
  list-style: none;
  margin: 0;
}

.pagination li {
  font-size: var(--text-small-regular-font-size);
  margin: auto;
  cursor: pointer;
}

.pagination li.active {
  color: #0e3feb;
  font-weight: bold;
}

.pagination li a {
  color: #333;
  text-decoration: none;
  font-size: 15px;
  padding: 8px 12px;
  border-radius: 5px;
}
.pagination li a:hover {
  background-color: #ddd;
  border-radius: 50%;
}

.pagination li.disabled a {
  color: #6c757d;
  border-radius: 50%;
  cursor: not-allowed !important;
  pointer-events: none;
}

.items-per-page-container,
.pagination-container,
.items-per-page-container select {
  color: var(--contentdark);
  font-family: var(--text-small-medium-font-family);
  font-weight: var(--text-small-medium-font-weight);
  font-size: var(--text-small-medium-font-size);
  letter-spacing: var(--text-small-medium-letter-spacing);
  line-height: var(--text-small-medium-line-height);
}

.items-per-page-container select {
  font-size: var(--text-small-medium-font-size);
  line-height: var(--text-small-medium-line-height);
}

.footerContainer {
  background: var(--surfacelight);
  width: 100%;
}
.selectPage {
  border-color: #dee2e6;
  margin-right: 5px;
  font-size: 15px !important;
}
.bsl-pagin-disabled {
  color: #6c757d;
  cursor: not-allowed !important;
  pointer-events: none;
}
.footerContainer > div > ul > li.active > a {
  width: 25px;
  border: 2px solid var(--bluenormal) !important;
  border-radius: 50%;
  color: #fff;
  padding: 3px 8px !important;
  background-color: var(--bluenormal);
}
.footerContainer > div > ul > li.active > a:hover {
  width: 25px;
  border: 2px solid var(--bluenormal) !important;
  border-radius: 50%;
  color: #fff;
  padding: 3px 8px !important;
  background-color: var(--bluenormal);
}
.footerContainer > div > ul > li:first-child > a,
.footerContainer > div > ul > li:last-child > a {
  font-size: 20px !important;
}

.footerContainer > div > ul > li:not(:first-child) > a,
.footerContainer > div > ul > li:not(:last-child) > a {
  font-size: var(--text-extralarge-regular-font-style)!important;
}
