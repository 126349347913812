/* globals.css */
:root {
  --surfacelight: rgba(245, 247, 249, 1);
  --bluenormal: rgba(14, 63, 235, 1);
  --contentnormal: rgba(79, 94, 113, 1);
  --whitewhite: rgba(255, 255, 255, 1);
  --bluedark-hover: rgba(8, 38, 141, 1);
  --bluedark: #0b2fb0;
  --contentdark: rgba(37, 42, 49, 1);
  --surfacedark: rgba(186, 199, 213, 1);
  --contentlight: rgba(105, 125, 149, 1);
  --surfacenormal: rgba(232, 237, 241, 1);
  --contentlight-hover: rgba(93, 115, 142, 1);
  --whitewhite-hover: rgba(241, 244, 247, 1);
  --bluenormal-hover: rgba(13, 57, 212, 1);
  --bluenormal-selected-bg: rgba(209, 218, 251, 1);
  --bluenormal-selected-color: rgba(36, 80, 236, 1);
  --rednormal: rgba(212, 0, 0, 1);
  --greennormal-hover: rgba(0, 194, 126, 1);
  --white: rgba(255, 255, 255, 1);
  --bluelight: rgba(231, 236, 253, 1);
  --redlight: rgba(251, 230, 230, 1);
  --redlight-hover: rgba(249, 217, 217, 1);
  --greenlight: rgba(230, 251, 244, 1);
  --greenlight-hover: rgba(217, 249, 238, 1);
  --surfacenormal: rgba(232, 237, 241, 1);
  --surfacelight: rgba(245, 247, 249, 1);
  --whitewhite-hover: rgba(241, 244, 247, 1);
  --warningwarning: rgba(255, 201, 70, 1);
  --surfacelight-hover: rgba(229, 234, 239, 1);
  --bluenormal-hover: rgba(13, 57, 212, 1);
  --contentlight-hover: rgba(93, 115, 142, 1);
  --headings-title-2-font-family: "OpenSans-SemiBold", Helvetica;
  --headings-title-2-font-weight: 600;
  --headings-title-2-font-size: 22px;
  --headings-title-2-letter-spacing: 0px;
  --headings-title-2-line-height: 28px;
  --headings-title-2-font-style: normal;
  --text-extralarge-regular-font-family: "OpenSans-Regular", Helvetica;
  --text-extralarge-regular-font-weight: 400;
  --text-extralarge-regular-font-size: 18px;
  --text-extralarge-regular-letter-spacing: 0px;
  --text-extralarge-regular-line-height: 24px;
  --text-extralarge-regular-font-style: normal;
  --text-normal-medium-font-family: "OpenSans-SemiBold", Helvetica;
  --text-normal-medium-font-weight: 600;
  --text-normal-medium-font-size: 14px;
  --text-normal-medium-letter-spacing: 0px;
  --text-normal-medium-line-height: 20px;
  --text-normal-medium-font-style: normal;
  --text-normal-regular-font-family: "OpenSans-Regular", Helvetica;
  --text-normal-regular-font-weight: 400;
  --text-normal-regular-font-size: 14px;
  --text-normal-regular-letter-spacing: 0px;
  --text-normal-regular-line-height: 20px;
  --text-normal-regular-font-style: normal;
  --headings-title-1-font-family: "OpenSans-Bold", Helvetica;
  --headings-title-1-font-weight: 700;
  --headings-title-1-font-size: 28px;
  --headings-title-1-letter-spacing: 0px;
  --headings-title-1-line-height: 32px;
  --headings-title-1-font-style: normal;
  --text-small-regular-font-family: "OpenSans-Regular", Helvetica;
  --text-small-regular-font-weight: 400;
  --text-small-regular-font-size: 13px;
  --text-small-regular-letter-spacing: 0px;
  --text-small-regular-line-height: 16px;
  --text-small-regular-font-style: normal;
  --text-small-medium-font-family: "OpenSans-SemiBold", Helvetica;
  --text-small-medium-font-weight: 600;
  --text-small-medium-font-size: 14px;
  --text-small-medium-letter-spacing: 0px;
  --text-small-medium-line-height: 16px;
  --text-small-medium-font-style: normal;
  --text-verysmall-medium-font-family: "OpenSans-SemiBold", Helvetica;
  --text-verysmall-medium-font-weight: 600;
  --text-verysmall-medium-font-size: 10px;
  --text-verysmall-medium-letter-spacing: 0px;
  --text-verysmall-medium-line-height: 12px;
  --text-verysmall-medium-font-style: normal;
  --text-verysmall-regular-font-family: "OpenSans-Regular", Helvetica;
  --text-verysmall-regular-font-weight: 400;
  --text-verysmall-regular-font-size: 10px;
  --text-verysmall-regular-letter-spacing: 0px;
  --text-verysmall-regular-line-height: 12px;
  --text-verysmall-regular-font-style: normal;
  --large: 0px 20px 50px 0px rgba(0, 26, 51, 0.1);
}

/* Reset CSS ou styles généraux de l'application */
body {
  margin: 0;
  padding: 0;
  font-family: "OpenSans-Regular", Helvetica, sans-serif;
  /* ... d'autres styles globaux pour le corps du document */
}

/* Styles de base pour d'autres éléments globaux */
a {
  text-decoration: none;
  color: var(--bluenormal);
}

/* ... d'autres styles globaux */
.cursor-pointer {
  cursor: pointer;
}
.btn-outline-primary {
  --bs-btn-color: rgba(14, 63, 235, 1) !important;
  --bs-btn-border-color: rgba(14, 63, 235, 1) !important;
  --bs-btn-hover-bg: rgba(14, 63, 235, 1) !important;
  --bs-btn-hover-border-color: rgba(14, 63, 235, 1) !important;
  --bs-btn-active-bg: rgba(14, 63, 235, 1) !important;
  --bs-btn-active-border-color: rgba(14, 63, 235, 1) !important;
  --bs-btn-disabled-color: rgba(14, 63, 235, 1) !important;
  --bs-btn-disabled-border-color: rgba(14, 63, 235, 1) !important;
}
.btn-primary {
  --bs-btn-bg: rgba(14, 63, 235, 1) !important;
  --bs-btn-border-color: rgba(14, 63, 235, 1) !important;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-disabled-bg: rgba(14, 63, 235, 1) !important;
  --bs-btn-disabled-border-color: rgba(14, 63, 235, 1) !important;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}

.bsl-color {
  color: var(--contentnormal) !important;
}
