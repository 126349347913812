.tt-page-title {
  display: flex;
  height: 64px;
  align-items: center;
  gap: 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  z-index: 1;
}

.tt-title {
  position: relative;
  flex: 1;
  font-family: var(--headings-title-2-font-family);
  font-weight: var(--headings-title-2-font-weight);
  color: var(--contentdark);
  font-size: var(--headings-title-2-font-size);
  letter-spacing: var(--headings-title-2-letter-spacing);
  line-height: var(--headings-title-2-line-height);
  font-style: var(--headings-title-2-font-style);
}
.tt-mingcute-add-line {
  position: relative;
  width: 20px;
  height: 20px;
  color: var(--bluelight);
}
